export const customerWalletData = [
  {
    id: 1,
    provider: 'John Smith',
    customer: 'Ruban',
    amount: '$7.5',
    reason: 'Deposit',
    status: 'Completed',
    date: '07 Oct 2023',
  },
  {
    id: 2,
    provider: 'Amanda',
    customer: 'Adrian',
    amount: '$0',
    reason: 'Withdrawn',
    status: 'Completed',
    date: '07 Oct 2023',
  },
  {
    id: 3,
    provider: 'Zela',
    customer: 'Amanda',
    amount: '$6.5',
    reason: 'Deposit',
    status: 'Completed',
    date: '17 Oct 2023',
  },
  {
    id: 4,
    provider: 'Aaron',
    customer: 'James',
    amount: '$0.00',
    reason: 'Withdrawn',
    status: 'Completed',
    date: '17 Oct 2023',
  },
  {
    id: 5,
    provider: 'Mark',
    customer: 'John Smith',
    amount: '$7.5',
    reason: 'Deposit',
    status: 'Completed',
    date: '07 Oct 2023',
  },
  {
    id: 6,
    provider: 'Andrew',
    customer: 'Johnny',
    amount: '$0',
    reason: 'Withdrawn',
    status: 'Completed',
    date: '07 Oct 2023',
  },
  {
    id: 7,
    provider: 'Amanda',
    customer: 'Amanda',
    amount: '$6.5',
    reason: 'Deposit',
    status: 'Completed',
    date: '17 Oct 2023',
  },
  {
    id: 8,
    provider: 'James',
    customer: 'Mandrid',
    amount: '$0.00',
    reason: 'Withdrawn',
    status: 'Completed',
    date: '17 Oct 2023',
  },
];
