export const categoriesData = [
  {
    id: 1,
    category: 'Construction',
    categorySlug: 'construction',
    Date: '28 Sep 2023',
    featured: '',
    Action: '',
  },
  {
    id: 2,
    category: 'Car Wash',
    categorySlug: 'car-wash',
    Date: '17 Sep 2023',
    featured: '',
    Action: '',
  },
  {
    id: 3,
    category: 'Computer',
    categorySlug: 'computer',
    Date: '13 Sep 2023',
    featured: '',
    Action: '',
  },
  {
    id: 4,
    category: 'Electrical',
    categorySlug: 'electrical',
    Date: '07 Sep 2023',
    featured: '',
    Action: '',
  },
  {
    id: 5,
    category: 'Cleaning',
    categorySlug: 'cleaning',
    Date: '07 Sep 2023',
    featured: '',
    Action: '',
  },
  {
    id: 6,
    category: 'Interior',
    categorySlug: 'interior',
    Date: '07 Sep 2023',
    featured: '',
    Action: '',
  },
  {
    id: 7,
    category: 'Carpentry',
    categorySlug: 'carpentry',
    Date: '22 Aug 2023',
    featured: '',
    Action: '',
  },
  {
    id: 8,
    category: 'Plumbing',
    categorySlug: 'plumbing',
    Date: '15 Aug 2023',
    featured: '',
    Action: '',
  },
];
