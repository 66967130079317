export const customersData = [
  {
    id: 1,
    img: 'assets/admin/img/provider/provider-01.jpg',
    customerName: 'Admin\n admin@example.com',
    mobile: '+1 888 888 8888',
    regDate: '30 Sep 2023',
    lastActivity: '2 mins ago',
    status: 'Active',
    action: '',
  },
  {
    id: 2,
    img: 'assets/admin/img/provider/provider-01.jpg',
    customerName: 'John Smith\n johnsmith@example.com',
    mobile: '518-837-9258',
    regDate: '27 Sep 2023',
    lastActivity: '10 mins ago',
    status: 'Active',
    action: '',
  },
  {
    id: 3,
    img: 'assets/admin/img/provider/provider-02.jpg',
    customerName: 'Robert\n robert@example.com',
    mobile: '302-372-7812',
    regDate: '25 Sep 2023',
    lastActivity: 'Online',
    status: 'Active',
    action: '',
  },
];
