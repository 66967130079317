export const currenciesData = [
  {
    id: 1,
    currencyName: 'US Dollar',
    code: 'USD',
    symbol: '$',
    exchangeRate: 'Default',
    actions: 'Action',
  },
  {
    id: 2,
    currencyName: 'Turkiye Lira',
    code: 'TRY',
    symbol: '₺',
    exchangeRate: 'Default',
    actions: 'Action',
  },
  {
    id: 3,
    currencyName: 'EU Euro',
    code: 'EUR',
    symbol: '€',
    exchangeRate: 'Default',
    actions: 'Action',
  },
  {
    id: 4,
    currencyName: 'India Rupee',
    code: 'INR',
    symbol: '₹',
    exchangeRate: 'Default',
    actions: 'Action',
  },
  {
    id: 5,
    currencyName: 'England Pound',
    code: 'GBP',
    symbol: '£',
    exchangeRate: 'Default',
    actions: 'Action',
  },
];
