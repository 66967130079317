export const couponsData = [
  {
    id: 1,
    name: 'New Year',
    code: 'NEW2023',
    type: 'Percentage',
    discount: '5%',
    limit: '100',
    used: '1',
    validDate: '02 Sep 2023 - 10 Sep 2023 \n  Once per customer',
    serviceName: 'All Services',
    status: 'Action',
    action: '',
  },
  {
    id: 2,
    name: 'Christmas',
    code: 'JOLLY15',
    type: 'Fixed',
    discount: '2%',
    limit: '100',
    used: '1',
    validDate: '04 Sep 2023 - 12 Sep 2023 \n  Once per customer',
    serviceName: 'House Cleaning   +1 more',
    status: 'Inaction',
    action: '',
  },
  {
    id: 3,
    name: 'Valentines',
    code: 'LOVER',
    type: 'Percentage',
    discount: '10%',
    limit: '100',
    used: '1',
    validDate: '23 Sep 2023 - 27 Sep 2023\n  Once per customer',
    serviceName: 'All Services',
    status: 'Action',
    action: '',
  },
  {
    id: 5,
    name: 'Halloween',
    code: 'SPOOKY15',
    type: 'Fixed',
    discount: '3%',
    limit: '100',
    used: '1',
    validDate: '23 Sep 2023 - 27 Sep 2023               Once per customer',
    serviceName: 'Computer Repair   +1 more',
    status: 'Inaction',
    action: '',
  },
  {
    id: 6,
    name: 'Black Friday',
    code: 'GIFTGUIDE',
    type: 'Percentage',
    discount: '3%',
    limit: '100',
    used: '1',
    validDate: '23 Sep 2023 - 27 Sep 2023          Once per customer',
    serviceName: 'Car Repair',
    status: 'Action',
    action: '',
  },
];
