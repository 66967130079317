export const bookingCompletedData = [
  {
    id: 1,
    date: '28 Sep 2023',
    bookingTime: '10:00:00 - 11:00:00',
    img: 'assets/admin/img/customer/user-01.jpg',
    provider: 'John Smith',
    userImg: 'assets/admin/img/customer/user-01.jpg',
    user: 'Sharon',
    serviceImg: 'assets/admin/img/services/service-01.jpg',
    service: 'Computer Repair',
    amount: '$80',
    status: 'Completed',
    dateAndTime: '07 Oct 2023 11:22:51',
    action: 'Select Status\n  Pending\n Inprogress\n Completed\n cancelled',
  },
  {
    id: 2,
    date: '10 Sep 2023',
    bookingTime: '18:00:00 - 19:00:00',
    img: 'assets/admin/img/customer/user-02.jpg',
    provider: 'Johnny',
    userImg: 'assets/admin/img/customer/user-02.jpg',
    user: 'Pricilla',
    serviceImg: 'assets/admin/img/services/service-02.jpg',
    service: 'Car Repair Services',
    amount: '$50',
    status: 'Completed',
    dateAndTime: '07 Oct 2023 11:22:51',
    action: 'Select Status\n  Pending\n Inprogress\n Completed\n cancelled',
  },
  {
    id: 3,
    date: '25 Sep 2023',
    bookingTime: '12:00:00 - 13:00:00',
    img: 'assets/admin/img/customer/user-03.jpg',
    provider: 'Robert',
    userImg: 'assets/admin/img/customer/user-03.jpg',
    user: 'Amanda',
    serviceImg: 'assets/admin/img/services/service-03.jpg',
    service: 'Steam Car Wash',
    amount: '$50',
    status: 'Completed',
    dateAndTime: '07 Oct 2023 11:22:51',
    action: 'Select Status\n  Pending\n Inprogress\n Completed\n cancelled',
  },
  {
    id: 4,
    date: '08 Sep 2023',
    bookingTime: '07 Oct 2023 11:22:51',
    img: 'assets/admin/img/customer/user-01.jpg',
    provider: 'Sharonda',
    userImg: 'assets/admin/img/customer/user-04.jpg',
    user: 'James',
    serviceImg: 'assets/admin/img/services/service-04.jpg',
    service: 'House Cleaning',
    amount: '$50',
    status: 'Completed',
    dateAndTime: '07 Oct 2023 11:22:51',
    action: 'Select Status\n  Pending\n Inprogress\n Completed\n cancelled',
  },
];
