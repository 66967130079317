export const emailTemplateData = [
  {
    referenceId: 1,
    emailTitle: 'Registration Confirmation',
    date: '28 Sep 2023 16:43:20',
    action: '',
  },
  {
    referenceId: 2,
    emailTitle: 'User Booked Confirmation',
    date: '27 Sep 2023 10:43:20',
    action: '',
  },
  {
    referenceId: 3,
    emailTitle: 'Provider Booked Confirmation',
    date: '23 Sep 2023 10:43:20',
    action: '',
  },
  {
    referenceId: 4,
    emailTitle: 'Booking Status',
    date: '23 Sep 2023 10:43:20',
    action: '',
  },
  {
    referenceId: 5,
    emailTitle: 'Admin Refund',
    date: '28 Sep 2023 16:43:20',
    action: '',
  },
  {
    referenceId: 6,
    emailTitle: 'Service Approval By Admin',
    date: '27 Sep 2023 10:43:20',
    action: '',
  },
  {
    referenceId: 7,
    emailTitle: 'Subscription Expires',
    date: '23 Sep 2023 10:43:20',
    action: '',
  },
  {
    referenceId: 8,
    emailTitle: 'Provider Booked Confirmation',
    date: '23 Sep 2023 10:43:20',
    action: '',
  },
];
