export const announcementsData = [
  {
    '#': '1',
    subject: 'Offer',
    message: 'Special offers for service booking',
    sendTo: 'Provider',
    date: '13 Oct 2023 12:17:00',
  },
  {
    '#': '2',
    subject: 'New Features',
    message: 'Two Factor Authendication added',
    sendTo: 'User',
    date: '12 Oct 2023 12:17:00',
  },
  {
    '#': '3',
    subject: 'Promotion',
    message: 'Sales promotion offers for Provider',
    sendTo: 'Provider',
    date: '13 Oct 2023 12:17:00',
  },
  {
    '#': '4',
    subject: 'Selling',
    message: 'Best selling service will be awarded',
    sendTo: 'User',
    date: '12 Oct 2023 12:17:00',
  },
];
