export const cashOnDeliveryData = [
  {
    id: 1,
    service: 'Computer Repair',
    providerName: 'John Smith',
    userName: 'Sharon',
    amount: '$80',
    status: 'Unpaid',
    date: '28 Sep 2023',
    serviceStatus: 'Inprogress',
  },
  {
    id: 2,
    service: 'Car Repair services',
    providerName: 'Johnny',
    userName: 'Pricilla',
    amount: '$50',
    status: 'Paid',
    date: '22 Sep 2023',
    serviceStatus: 'Payment Completed',
  },
  {
    id: 3,
    service: 'Steam Car Wash',
    providerName: 'Robert',
    userName: 'Amanda',
    amount: '$100',
    status: 'Paid',
    date: '20 Sep 2023',
    serviceStatus: 'Payment Completed',
  },
  {
    id: 4,
    service: 'House Cleaning',
    providerName: 'Sharonda',
    userName: 'James',
    amount: '$34',
    status: 'Paid',
    date: '15 Sep 2023',
    serviceStatus: 'Payment Completed',
  },
  {
    id: 5,
    service: 'Computer Repair',
    providerName: 'John Smith',
    userName: 'Sharon',
    amount: '$46',
    status: 'Paid',
    date: '23 Sep 2023',
    serviceStatus: 'Payment Completed',
  },
  {
    id: 6,
    service: 'Car Repair services',
    providerName: 'Johnny',
    userName: 'Pricilla',
    amount: '$20',
    status: 'Unpaid',
    date: '30 Sep 2023',
    serviceStatus: 'Pending',
  },
  {
    id: 7,
    service: 'Steam Car Wash',
    providerName: 'Robert',
    userName: 'Amanda',
    amount: '$100',
    status: 'Paid',
    date: '20 Sep 2023',
    serviceStatus: 'Payment Completed',
  },
  {
    id: 8,
    service: 'House Cleaning',
    providerName: 'Sharonda',
    userName: 'James',
    amount: '$34',
    status: 'Paid',
    date: '15 Sep 2023',
    serviceStatus: 'Payment Completed',
  },
];
