export const citiesData = [
  {
    id: 1,
    img: 'assets/admin/img/flags/us.png',
    countryName: 'AmericanSamoa(+684)',
    stateName: 'SwainsIsland',
    cityName: 'Gandzasar',
    Action: '',
  },
  {
    id: 2,
    img: 'assets/admin/img/flags/ad.png',
    countryName: 'Andorra(+376)',
    stateName: 'SwainsIsland',
    cityName: 'Escaldes-Engordany',
    Action: '',
  },

  {
    id: 3,
    img: 'assets/admin/img/flags/ao.png',
    countryName: 'Angola(+244)',
    stateName: 'Benguela',
    cityName: 'Chissamba',
    Action: '',
  },
  {
    id: 4,
    img: 'assets/admin/img/flags/ai.png',
    countryName: 'Anguilla(+1264)',
    stateName: 'TheValley',
    cityName: 'Illinois',
    Action: '',
  },
  {
    id: 5,
    img: 'assets/admin/img/flags/qa.png',

    countryName: 'Antarctica(+672)',
    stateName: 'VictoriaLand',
    cityName: 'Melbourne',
    Action: '',
  },
  {
    id: 6,
    img: 'assets/admin/img/flags/ag.png',

    countryName: 'Antigua&Barbuda(+1268)',
    stateName: 'SaintPaul',
    cityName: 'Maplewood',
    Action: '',
  },
  {
    id: 7,
    img: 'assets/admin/img/flags/ar.png',

    countryName: 'Argentina(+54)',
    stateName: 'SantaFe',
    cityName: 'Rosario',
    Action: '',
  },
  {
    id: 8,
    img: 'assets/admin/img/flags/au.png',

    countryName: 'Australia(+61)',
    stateName: 'Queensland',
    cityName: 'Atherton',
    Action: '',
  },
];
