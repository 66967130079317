import React from 'react';

const BookingFooter = () => {
  return (
    <>
      {/* Footer */}
      <div className="footer-copyright ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>Copyright © 2024. All Rights Reserved Vimaid</p>
            </div>
          </div>
        </div>
      </div>
      {/* /Footer */}
    </>
  );
};

export default BookingFooter;
