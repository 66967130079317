import React from 'react';

const AuthFooter = () => {
  return (
    <>
      {/* Footer */}
      <div className="footer-copyright  bg-transparent">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>Copyright © 2024. All Rights Reserved Vimaid</p>
            </div>
          </div>
        </div>
      </div>
      {/* /Footer */}
    </>
  );
};

export default AuthFooter;
