import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider, { Settings } from 'react-slick';
import FooterThree from './footer-three';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { Dropdown } from 'primereact/dropdown';
import HomeHeader from '../header/home-header';
import { all_routes } from '../../../../core/data/routes/all_routes';

interface Review {
  review: string;
  name: string;
  country: string;
  img: string;
}

const HomeThree = () => {
  const routes = all_routes;

  // Define the reviews array
  const reviews: Review[] = [
    {
      review:
        '“ Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Egestas erat viverra scelerisque bibendum. “',
      name: 'John Doe',
      country: 'USA',
      img: 'assets/img/profiles/avatar-04.jpg',
    },
    {
      review:
        '“ Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Egestas erat viverra scelerisque bibendum. “',
      name: 'Paul Walker',
      country: 'New York, USA',
      img: 'assets/img/profiles/avatar-01.jpg',
    },
    {
      review:
        '“ Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Egestas erat viverra scelerisque bibendum. “',
      name: 'Anthony Walker',
      country: 'New York, USA',
      img: 'assets/img/profiles/avatar-02.jpg',
    },
    {
      review:
        '“ Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Egestas erat viverra scelerisque bibendum. “',
      name: 'Van Diesel',
      country: 'New York, USA',
      img: 'assets/img/profiles/avatar-03.jpg',
    },
    // Add more review objects as needed
  ];

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  // Handle AOS refresh on scroll
  useEffect(() => {
    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Slider references using useRef
  const slider1Ref = useRef<Slider>(null);
  const slider2Ref = useRef<Slider>(null);

  // Slider settings
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  };

  const oneSettings: Settings = { ...settings };
  const twoSettings: Settings = { ...settings };

  const slideConfig: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const slideConfig2: Settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show three images in parallel
    slidesToScroll: 1,
    centerMode: true,
  };

  // State for Dropdown and DatePicker
  const [selectedSub, setSelectedSub] = useState<string | null>(null);
  const category = [
    { name: 'Select Service type' },
    { name: 'Toronto' }, // Corrected spelling from 'Tornoto'
    { name: 'Texas' },
  ];
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  // Handle Slider synchronization
  const handleSlider1Change = (index: number) => {
    slider2Ref.current?.slickGoTo(index);
  };

  const handleSlider2Change = (index: number) => {
    slider1Ref.current?.slickGoTo(index);
  };

  return (
    <>
      <HomeHeader type={4} />
      {/* Banner Section */}
      <section className="hero-section-three">
        <div className="container">
          <div className="home-banner home-banner-three aos" data-aos="fade-up">
            <div className="row align-items-center w-100">
              <div className="col-lg-6 col-md-12 mx-auto">
                <div className="section-search section-section-three">
                  <h4>Best Saloon Template</h4>
                  <h1>Find the Awesome Saloon’s Near you</h1>
                  <p>
                    Hair and SPA Salons are fully integrated with theme tools
                    that you can use for the promotion of your business.
                  </p>
                  <div className="d-flex">
                    <Link
                      to={''}
                      className="btn btn-white d-flex align-items-center"
                    >
                      Get Started{' '}
                      <i className="ti ti-circle-chevron-right ms-1"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
          <div className="search-box search-box-three aos" data-aos="fade-up">
            <form action={''} className="d-flex search-form-profile">
              <div className="searchbox-list">
                <div className="search-input search-input-three d-flex align-items-center">
                  <i className="feather icon-check-square me-2" />
                  <div className="form-group m-0">
                    <Dropdown
                      value={selectedSub}
                      onChange={(e) => setSelectedSub(e.value)}
                      options={category}
                      optionLabel="name"
                      placeholder="Job Title"
                      className="select select border-none w-100"
                    />
                  </div>
                </div>
                <div className="search-input search-input-three">
                  <Icon.MapPin className="feather icon-map-pin me-2" />
                  <div className="form-group mb-0">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Location"
                    />
                  </div>
                </div>
                <div className="search-input search-input-three">
                  <i className="feather icon-calendar me-2" />
                  <div className="form-group mb-0">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      placeholderText="Select Date"
                      className="form-control floating datetimepicker"
                    />
                  </div>
                </div>
              </div>
              <div className="search-btn search-btn-three">
                <button className="btn btn-primary" type="submit">
                  <Icon.Search className="react-feather icon-custom" />
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* /Banner Section */}

      {/* Services Section */}
      <section className="services-section">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>Our Services</h2>
                  <div className="our-img-all-1">
                    <ImageWithBasePath
                      src="assets/img/icons/cuttor-small.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...settings} className="services-slider">
                {/* Repeat service items as needed */}
                <div className="services-all">
                  <div className="services-main-img">
                    <Link to="">
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service Image"
                        src="assets/img/services/service-81.jpg"
                      />
                    </Link>
                    <div className="service-foot">
                      <ImageWithBasePath
                        src="assets/img/icons/cuttor.svg"
                        alt="Saloon"
                        className="mb-2"
                      />
                      <h4 className="mb-1">Haircut</h4>
                      <h6 className="mb-1">25 Saloons</h6>
                    </div>
                  </div>
                </div>
                <div className="services-all">
                  <div className="services-main-img">
                    <Link to="">
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service Image"
                        src="assets/img/services/service-83.jpg"
                      />
                    </Link>
                    <div className="service-foot">
                      <ImageWithBasePath
                        src="assets/img/icons/trim.svg"
                        alt="Saloon"
                        className="mb-2"
                      />
                      <h4 className="mb-1">Trimming</h4>
                      <h6 className="mb-1">25 Saloons</h6>
                    </div>
                  </div>
                </div>
                <div className="services-all">
                  <div className="services-main-img">
                    <Link to="">
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service Image"
                        src="assets/img/services/service-84.jpg"
                      />
                    </Link>
                    <div className="service-foot">
                      <ImageWithBasePath
                        src="assets/img/icons/style-logo.svg"
                        alt="Saloon"
                        className="mb-2"
                      />
                      <h4 className="mb-1">Saloons</h4>
                      <h6 className="mb-1">25 Saloons</h6>
                    </div>
                  </div>
                </div>
                <div className="services-all">
                  <div className="services-main-img">
                    <Link to="">
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service Image"
                        src="assets/img/services/service-82.jpg"
                      />
                    </Link>
                    <div className="service-foot">
                      <ImageWithBasePath
                        src="assets/img/icons/shave.svg"
                        alt="Saloon"
                        className="mb-2"
                      />
                      <h4 className="mb-1">Shaving</h4>
                      <h6 className="mb-1">25 Saloons</h6>
                    </div>
                  </div>
                </div>
                {/* Add more service items as needed */}
              </Slider>
            </div>
          </div>
        </div>
        <div className="sidecircle-ryt">
          <ImageWithBasePath src="assets/img/side-circle.png" alt="Circle" />
        </div>
      </section>
      {/* /Services Section */}

      {/* Featured Saloons Section */}
      <section className="service-section featured-saloons">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>Featured Saloons</h2>
                  <div className="our-img-all-1">
                    <ImageWithBasePath
                      src="assets/img/icons/cuttor-small.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Featured Saloon Card */}
            <div className="col-xl-4 col-md-6 col-sm-6 col-12 d-flex">
              <div className="card flex-fill aos" data-aos="fade-right">
                <div className="card-body">
                  <div className="service-widget">
                    <div className="service-img service-show-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service Image"
                          src="assets/img/services/service-b-01.jpg"
                        />
                      </Link>
                      <div className="item-info item-infos">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                    </div>
                    <div className="service-content service-content-three">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Saloon 24 Hair Designers
                        </Link>
                      </h3>
                      <ul>
                        <li>Deep Pore Cleansing</li>
                        <li>Straight Razor Shave</li>
                      </ul>
                      <div className="main-saloons-profile">
                        <div className="saloon-profile-left">
                          <Link
                            to={routes.providerDetails}
                            className="saloon-img"
                          >
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-20.jpg"
                              alt="User"
                            />
                          </Link>
                          <div className="saloon-content">
                            <div className="saloon-content-top mb-1">
                              <i className="feather icon-clock" />
                              <span>07:00 AM - 11:00 PM </span>
                            </div>
                            <div className="saloon-content-btn">
                              <span>
                                <i className="feather icon-map-pin" />
                              </span>
                              <span>Main Boulevard, Lahore,</span>
                            </div>
                          </div>
                        </div>
                        <div className="saloon-right">
                          <span>$70</span>
                        </div>
                      </div>
                      <div className="saloon-bottom">
                        <Link
                          to={routes.serviceDetails1}
                          className="btn btn-dark"
                        >
                          <i className="feather icon-calendar me-2" />
                          Book Appointment
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Repeat Featured Saloon Cards as needed */}
            {/* ... Other featured saloon cards ... */}
            <div className="col-xl-4 col-md-6 col-sm-6 col-12 d-flex">
              <div className="card flex-fill aos" data-aos="fade-up">
                <div className="card-body">
                  <div className="service-widget">
                    <div className="service-img service-show-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service Image"
                          src="assets/img/services/service-b-02.jpg"
                        />
                      </Link>
                      <div className="item-info item-infos">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                    </div>
                    <div className="service-content service-content-three">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          The Rockstar Barber
                        </Link>
                      </h3>
                      <ul>
                        <li>Buzz Cut</li>
                        <li>Blowout</li>
                      </ul>
                      <div className="main-saloons-profile">
                        <div className="saloon-profile-left">
                          <Link
                            to={routes.providerDetails}
                            className="saloon-img"
                          >
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-19.jpg"
                              alt="User"
                            />
                          </Link>
                          <div className="saloon-content">
                            <div className="saloon-content-top mb-1">
                              <i className="feather icon-clock" />
                              <span>09:00 AM - 05:00 PM </span>
                            </div>
                            <div className="saloon-content-btn">
                              <i className="feather icon-map-pin" />
                              <span>30 Small Street, New York</span>
                            </div>
                          </div>
                        </div>
                        <div className="saloon-right">
                          <span>$50</span>
                        </div>
                      </div>
                      <div className="saloon-bottom">
                        <Link
                          to={routes.serviceDetails1}
                          className="btn btn-dark"
                        >
                          <i className="feather icon-calendar me-2" />
                          Book Appointment
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Add more featured saloon cards as needed */}
          </div>
        </div>
      </section>
      {/* /Featured Saloons Section */}

      {/* Appointment Section */}
      <section
        className="appointment-section appointment-section-four aos"
        data-aos="fade-up"
      >
        <div className="index-4">
          <ImageWithBasePath
            src="assets/img/icons/saloon-bg.svg"
            className="img-fluid h-100 w-100"
            alt="bg"
          />
        </div>
        <div className="container">
          <div className="appointment-main">
            <Link to="#" className="btn btn-secondary text-white">
              GET A MODERN LOOK
            </Link>
            <h2>Up to 25% offer on First Appointment</h2>
            <p className="text-center">
              Each service provider will have a listing that typically includes
              a description of their services, pricing, availability, and any
              special offers or packages.
            </p>
            <div className="appointment-btn">
              <Link to={routes.freeTrail} className="btn btn-primary p-3">
                BOOK AN APPOINTMENT NOW
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* /Appointment Section */}

      {/* Featured Stylists Section */}
      <section className="services-section stylists-section">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>Featured Stylists</h2>
                  <div className="our-img-all-1">
                    <ImageWithBasePath
                      src="assets/img/icons/cuttor-small.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...oneSettings} className="stylists-slider aos">
                {/* Stylists Slider Items */}
                <div className="stylists-all card">
                  <div className="card-body">
                    <div className="stylists-main-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-image"
                          alt="Service Image"
                          src="assets/img/profiles/avatar-04.jpg"
                        />
                      </Link>
                    </div>
                    <div className="stylists-bottom">
                      <div className="stylists-rating mb-1">
                        <div className="rating mb-0">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                      <div className="stylists-foot">
                        <h4 className="mb-2">
                          <Link to={routes.serviceDetails1}>Evelyn Nelson</Link>
                        </h4>
                        <h6 className="mb-1">300 Bookings</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stylists-all card">
                  <div className="card-body">
                    <div className="stylists-main-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-image"
                          alt="Service Image"
                          src="assets/img/profiles/avatar-05.jpg"
                        />
                      </Link>
                    </div>
                    <div className="stylists-bottom">
                      <div className="stylists-foot">
                        <div className="stylists-rating mb-2">
                          <div className="rating mb-0">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                          </div>
                        </div>
                        <h4 className="mb-2">
                          <Link to={routes.serviceDetails1}>Michel</Link>
                        </h4>
                        <h6 className="mb-1">356 Bookings</h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Add more stylists as needed */}
                <div className="stylists-all card">
                  <div className="card-body">
                    <div className="stylists-main-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-image"
                          alt="Service Image"
                          src="assets/img/profiles/avatar-06.jpg"
                        />
                      </Link>
                    </div>
                    <div className="stylists-bottom">
                      <div className="stylists-foot">
                        <div className="stylists-rating mb-2">
                          <div className="rating mb-0">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                          </div>
                        </div>
                        <h4 className="mb-2">
                          <Link to={routes.serviceDetails1}>Thompson</Link>
                        </h4>
                        <h6 className="mb-1">145 Bookings</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stylists-all card">
                  <div className="card-body">
                    <div className="stylists-main-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-image"
                          alt="Service Image"
                          src="assets/img/profiles/avatar-07.jpg"
                        />
                      </Link>
                    </div>
                    <div className="stylists-bottom">
                      <div className="stylists-foot">
                        <div className="stylists-rating mb-2">
                          <div className="rating mb-0">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                          </div>
                        </div>
                        <h4 className="mb-2">
                          <Link to={routes.serviceDetails1}>Matthew Joe</Link>
                        </h4>
                        <h6 className="mb-1">256 Bookings</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stylists-all card">
                  <div className="card-body">
                    <div className="stylists-main-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-image"
                          alt="Service Image"
                          src="assets/img/profiles/avatar-08.jpg"
                        />
                      </Link>
                    </div>
                    <div className="stylists-bottom">
                      <div className="stylists-foot">
                        <div className="stylists-rating mb-2">
                          <div className="rating mb-0">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                          </div>
                        </div>
                        <h4 className="mb-2">
                          <Link to={routes.serviceDetails1}>James George</Link>
                        </h4>
                        <h6 className="mb-1">300 Bookings</h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Add more stylists as needed */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* /Featured Stylists Section */}

      {/* Popular Locations Section */}
      <section className="service-section populars-section">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>Popular Locations</h2>
                  <div className="our-img-all-1">
                    <ImageWithBasePath
                      src="assets/img/icons/cuttor-small.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row aos" data-aos="fade-up">
            {/* Popular Location Card */}
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div>
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-105.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <h3 className="mb-2">
                      <Link to={routes.serviceDetails1}>USA</Link>
                    </h3>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            {/* Repeat Popular Location Cards as needed */}
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div>
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-92.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <h3 className="mb-2">
                      <Link to={routes.serviceDetails1}>UK</Link>
                    </h3>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div>
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-93.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <h3 className="mb-2">
                      <Link to={routes.serviceDetails1}>Mexico</Link>
                    </h3>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div>
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-94.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <h3 className="mb-2">
                      <Link to={routes.serviceDetails1}>UAE</Link>
                    </h3>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            {/* Add more popular location cards as needed */}
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div>
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-95.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <h3 className="mb-2">
                      <Link to={routes.serviceDetails1}>France</Link>
                    </h3>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div>
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-96.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <h3 className="mb-2">
                      <Link to={routes.serviceDetails1}>Germany</Link>
                    </h3>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div>
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-97.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <h3 className="mb-2">
                      <Link to={routes.serviceDetails1}>Italy</Link>
                    </h3>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div>
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-98.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <h3 className="mb-2">
                      <Link to={routes.serviceDetails1}>Argentina</Link>
                    </h3>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-sec btn-saloons aos" data-aos="fade-up">
              <Link to={routes.search} className="btn btn-primary btn-view">
                VIEW ALL 590 LOCATION
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* /Popular Locations Section */}

      {/* How It Works Section */}
      <section className="works-section">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>How It Works</h2>
                  <div className="our-img-all-1">
                    <ImageWithBasePath
                      src="assets/img/icons/cuttor-small.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {/* How It Works Step 1 */}
            <div className="col-md-4 col-sm-6 col-12">
              <div className="works-main aos" data-aos="fade-right">
                <div className="works-tops">
                  <div className="works-top-img">
                    <ImageWithBasePath
                      src="assets/img/services/service-98.jpg"
                      alt="Discover"
                    />
                    <span>1</span>
                  </div>
                </div>
                <div className="works-bottom">
                  <Link to="#">
                    <h4 className="mb-2">Discover</h4>
                  </Link>
                  <p>
                    Customers can browse or search for specific products or
                    services using categories, filters, or search bars.
                  </p>
                </div>
              </div>
            </div>
            {/* How It Works Step 2 */}
            <div className="col-md-4 col-sm-6 col-12">
              <div className="works-main aos" data-aos="fade-up">
                <div className="works-tops">
                  <div className="works-top-img works-load-profile">
                    <ImageWithBasePath
                      src="assets/img/services/service-99.jpg"
                      alt="Book"
                    />
                    <span>2</span>
                  </div>
                </div>
                <div className="works-bottom">
                  <Link to="#">
                    <h4 className="mb-2">Book</h4>
                  </Link>
                  <p>
                    Customers can add items to their shopping cart. For
                    services, they may select a service and proceed to book.
                  </p>
                </div>
              </div>
            </div>
            {/* How It Works Step 3 */}
            <div className="col-md-4 col-sm-6 col-12">
              <div className="works-main aos" data-aos="fade-left">
                <div className="works-tops">
                  <div className="works-top-img">
                    <ImageWithBasePath
                      src="assets/img/services/service-100.jpg"
                      alt="Enjoy"
                    />
                    <span>3</span>
                  </div>
                </div>
                <div className="works-bottom">
                  <Link to="#">
                    <h4 className="mb-2">Enjoy</h4>
                  </Link>
                  <p>
                    The Customer fulfills the order by either providing the
                    service to the Customers &amp; Get leads from the local
                    Persons in our Platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /How It Works Section */}

      {/* Client Testimonials Section */}
      <section className="client-sections review-four">
        <div className="client-bg">
          <ImageWithBasePath
            src="assets/img/bg/client-bg1.svg"
            className="img-fluid h-100 w-100"
            alt="Background"
          />
        </div>
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content section-client">
                  <h2>What Our Client Says</h2>
                  <div className="our-img-all-1">
                    <ImageWithBasePath
                      src="assets/img/icons/cuttor-sm-white.svg"
                      alt="Service"
                    />
                  </div>
                  <p>
                    Description highlights the value of client feedback,
                    showcases real testimonials, and encourages potential
                    clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* Main Testimonials Slider */}
            <Slider
              {...slideConfig}
              className="slider say-about slider-for aos"
              afterChange={handleSlider1Change}
              ref={slider1Ref}
            >
              {reviews.map((data, index) => (
                <div key={index}>
                  <div className="review-love-group">
                    <div className="quote-love-img">
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/icons/quote.svg"
                        alt="Quote"
                      />
                    </div>
                    <p className="review-passage">“ {data.review} “</p>
                    <div className="say-name-blk text-center">
                      <h5>{data.name}</h5>
                      <p>{data.country}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            {/* Thumbnail Slider */}
            <Slider
              {...slideConfig2}
              className="slider client-img slider-nav client-pro aos"
              afterChange={handleSlider2Change}
              ref={slider2Ref}
            >
              {reviews.slice(0, 3).map((data, index) => (
                <div key={index} className="testimonial-thumb">
                  <ImageWithBasePath
                    src={data.img}
                    alt={`${data.name}'s avatar`}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      {/* /Client Testimonials Section */}

      {/* Latest News Section */}
      <section className="services-section latest-section blog-section-three">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>Our Latest News</h2>
                  <div className="our-img-all-1">
                    <ImageWithBasePath
                      src="assets/img/icons/cuttor-small.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...twoSettings} className="latest-slider aos">
                {/* Latest News Blog Post */}
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-101.jpg"
                        alt="Post Image"
                      />
                    </Link>
                    <div className="date">
                      15<span>Nov 2023</span>
                    </div>
                  </div>
                  <div className="blog-content mb-0">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Hair Cut</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Facial</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        Take advantage of trial periods or consultations
                      </Link>
                    </h3>
                    <div className="blog-view d-flex">
                      <Link
                        to={routes.blogDetails}
                        className="btn btn-light d-flex w-100 justify-content-center text-dark"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Repeat Latest News Blog Posts as needed */}
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-102.jpg"
                        alt="Post Image"
                      />
                    </Link>
                    <div className="date">
                      15<span>Nov 2023</span>
                    </div>
                  </div>
                  <div className="blog-content mb-0">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Hair Cut</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Facial</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        Maximize your business potential with the right service
                      </Link>
                    </h3>
                    <div className="blog-view d-flex">
                      <Link
                        to={routes.blogDetails}
                        className="btn btn-light d-flex w-100 justify-content-center text-dark"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-103.jpg"
                        alt="Post Image"
                      />
                    </Link>
                    <div className="date">
                      15<span>Nov 2023</span>
                    </div>
                  </div>
                  <div className="blog-content mb-0">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Hair Cut</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Facial</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        Specific features and benefits of your service.
                      </Link>
                    </h3>
                    <div className="blog-view d-flex">
                      <Link
                        to={routes.blogDetails}
                        className="btn btn-light d-flex w-100 justify-content-center text-dark"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-104.jpg"
                        alt="Post Image"
                      />
                    </Link>
                    <div className="date">
                      15<span>Nov 2023</span>
                    </div>
                  </div>
                  <div className="blog-content mb-0">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Hair Cut</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Facial</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        One of the biggest uses of service marketplace
                      </Link>
                    </h3>
                    <div className="blog-view d-flex">
                      <Link
                        to="#"
                        className="btn btn-light d-flex w-100 justify-content-center text-dark"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-102.jpg"
                        alt="Post Image"
                      />
                    </Link>
                    <div className="date">
                      15<span>Nov 2023</span>
                    </div>
                  </div>
                  <div className="blog-content mb-0">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Hair Cut</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Facial</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        One of the biggest uses of service marketplace
                      </Link>
                    </h3>
                    <div className="blog-view d-flex">
                      <Link
                        to="#"
                        className="btn btn-light d-flex w-100 justify-content-center text-dark"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Add more latest news blog posts as needed */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* /Latest News Section */}

      {/* Register Section */}
      <section className="register-section aos" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="register-content">
                <h2>Get Registered and List your Saloon for free!!!</h2>
                <div className="register-btn">
                  <Link to={routes.userSignup}>
                    <i className="feather icon-users me-2" />
                    Register Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Register Section */}

      <FooterThree />
    </>
  );
};

export default HomeThree;
