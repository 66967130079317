import React from 'react';
import { useLocation } from 'react-router-dom';
import ProviderHeader from './common/header';
import ProviderSidebar from './common/sidebar';
import ProvidersRoutes from './providers.routes';

const Providers = () => {
  const location = useLocation();

  // Define the paths where Header and Sidebar should be excluded
  const excludedPaths = [
    '/providers/authentication/provider-signup',
    '/providers/authentication/provider-signup-payment',
    '/providers/authentication/provider-signup-subscription',
  ];

  // Determine whether to show Header and Sidebar
  const shouldShowHeaderAndSidebar = !excludedPaths.includes(location.pathname);

  return (
    <>
      {shouldShowHeaderAndSidebar && <ProviderHeader />}
      {shouldShowHeaderAndSidebar && <ProviderSidebar />}
      <ProvidersRoutes />
    </>
  );
};

export default Providers;
