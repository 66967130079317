export const userData = [
  {
    id: 1,
    img: 'assets/admin/img/customer/user-01.jpg',
    name: 'Admin',
    email: 'admin@example.com',
    mobile: '+1 888 888 8888',
    role: 'Administrator',
    lastActivity: '2 mins ago',
    created: '30 Sep 2023',
    status: 'Active',
    action: '',
  },
  {
    id: 2,
    img: 'assets/admin/img/customer/user-02.jpg',
    name: 'John Smith',
    email: 'johnsmith@example.com',
    mobile: '518-837-9258',
    role: 'Manager',
    lastActivity: '10 mins ago',
    created: '27 Sep 2023',
    status: 'Active',
    action: '',
  },
  {
    id: 3,
    img: 'assets/admin/img/customer/user-03.jpg',
    name: 'Robert',
    email: 'robert@example.com',
    mobile: '302-372-7812',
    role: 'Accountant',
    lastActivity: 'Online',
    created: '25 Sep 2023',
    status: 'Active',
    action: '',
  },
];
