export const ContactMessages = [
  {
    id: 1,
    img: 'assets/img/customer/user-01.jpg',
    name: 'JohnSmith',
    email: 'john@example.com',
    phone: '+1347-679-8275',
    message: 'Need this script',
    date: '28 Sep 2023',
    Action: 'Delete',
  },
  {
    id: 2,
    img: 'assets/img/customer/user-02.jpg',
    name: 'Johnny',
    email: 'johnny@example.com',
    phone: '+1347-679-8275',
    message: 'Need this script',
    date: '25 Sep 2023',
    Action: '',
  },
  {
    id: 3,
    img: 'assets/img/customer/user-03.jpg',
    name: 'Robert',
    email: 'robert@example.com',
    phone: '+1347-679-8275',
    message: 'Need this script',
    date: '23 Sep 2023',
    Action: '',
  },
  {
    id: 4,
    img: 'assets/img/customer/user-04.jpg',
    name: 'Sharonda',
    email: 'sharonda@example.com',
    phone: '+1347-679-8275',
    message: 'Need this script',
    date: '03 Sep 2023',
    Action: '',
  },
];
