export const homeWork = [
  {
    id: 1,
    Slider: [
      { id: 1, Image: 'service-23.jpg' },
      { id: 2, Image: 'service-02.jpg' },
      { id: 3, Image: 'service-03.jpg' },
    ],
    TradeIcon: true,
    Avatar: 'avatar-05.jpg',
    Name: 'Trivala Anderson',
    Price: 'From $350',
    Title: 'Cabinet Installation',
    Reviews: '4.5 (254 Reviews)',
    Bookings: '3K Bookings',
  },
  {
    id: 2,
    Slider: [
      { id: 1, Image: 'service-24.jpg' },
      { id: 2, Image: 'service-05.jpg' },
      { id: 3, Image: 'service-06.jpg' },
    ],
    TradeIcon: false,
    Avatar: 'avatar-17.jpg',
    Name: 'William Tichenor',
    Price: 'From $350',
    Title: 'Express Car Wash',
    Reviews: '4.6 (214 Reviews)',
    Bookings: '2K Bookings',
  },
  {
    id: 3,
    Slider: [
      { id: 1, Image: 'service-25.jpg' },
      { id: 2, Image: 'service-08.jpg' },
      { id: 3, Image: 'service-11.jpg' },
    ],
    TradeIcon: true,
    Avatar: 'avatar-16.jpg',
    Name: 'Edwin Murphy',
    Price: 'From $280',
    Title: 'Haircut and Styling',
    Reviews: '4.7 (230 Reviews)',
    Bookings: '2K Bookings',
  },
  {
    id: 4,
    Slider: [
      { id: 1, Image: 'service-50.jpg' },
      { id: 2, Image: 'service-11.jpg' },
      { id: 3, Image: 'service-12.jpg' },
    ],
    TradeIcon: false,
    Avatar: 'avatar-06.jpg',
    Name: 'Wesley McClure',
    Price: 'From $400',
    Title: 'Roofing Services',
    Reviews: '4.4 (123 Reviews)',
    Bookings: '4K Bookings',
  },
  {
    id: 5,
    Slider: [
      { id: 1, Image: 'service-47.jpg' },
      { id: 2, Image: 'service-48.jpg' },
      { id: 3, Image: 'service-49.jpg' },
    ],
    TradeIcon: false,
    Avatar: 'avatar-09.jpg',
    Name: 'James Reese',
    Price: 'From $180',
    Title: 'Pure Home Cleaning',
    Reviews: '4.2 (300 Reviews)',
    Bookings: '1K Bookings',
  },
  {
    id: 6,
    Slider: [
      { id: 1, Image: 'service-51.jpg' },
      { id: 2, Image: 'service-05.jpg' },
      { id: 3, Image: 'service-06.jpg' },
    ],
    TradeIcon: false,
    Avatar: 'avatar-25.jpg',
    Name: 'Kimberly Carey',
    Price: 'From $120',
    Title: 'Crystal Shine Manicure',
    Reviews: '4.4 (270 Reviews)',
    Bookings: '2K Bookings',
  },
];
