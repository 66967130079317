import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';

const AuthFeature = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/admin')) {
      import('../style/admin/css/admin.css');
    } else {
      import('../style/scss/main.scss');
    }
  }, [location.pathname]);

  return (
    <>
      {/* Main Wrapper */}
      <div className="authentication-page">
        <div className="d-flex justify-content-between vh-100 overflow-auto flex-column">
          <Outlet />
        </div>
      </div>
      {/* /Main Wrapper */}
    </>
  );
};

export default AuthFeature;
