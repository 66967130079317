export const payoutRequestsDate = [
  {
    id: '1',
    img: 'assets/admin/img/customer/user-01.jpg',
    name: 'John Smith',
    payoutMethod: 'Stripe',
    amount: '$80',
    status: 'Pending',
    createdAt: '07 Oct 2023 11:22:51',
    action: 'Select Status\nPending\nInprogress\nCompleted\nCancelled', // Include newline characters
  },
  {
    id: '2',
    img: 'assets/admin/img/customer/user-02.jpg',
    name: 'Johnny',
    payoutMethod: 'Paypal',
    amount: '$50',
    status: 'Pending',
    createdAt: '15 Oct 2023 11:22:51',
    action: 'Select Status\nPending\nInprogress\nCompleted\nCancelled', // Include newline characters
  },
  {
    id: '3',
    img: 'assets/admin/img/customer/user-03.jpg',
    name: 'Robert',
    payoutMethod: 'Stripe',
    amount: '$50',
    status: 'Pending',
    createdAt: '21 Oct 2023 11:22:51',
    action: 'Select Status\nPending\nInprogress\nCompleted\nCancelled', // Include newline characters
  },
  {
    id: '4',
    img: 'assets/admin/img/customer/user-04.jpg',
    name: 'Sharonda',
    payoutMethod: 'Paypal',
    amount: '$50',
    status: 'Pending',
    createdAt: '07 Oct 2023 11:22:51',
    action: 'Select Status\nPending\nInprogress\nCompleted\nCancelled', // Include newline characters
  },
];
