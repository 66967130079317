export const countriesData = [
  {
    id: 1,
    countryCode: 'AS',
    countryId: 684,
    img: 'assets/admin/img/flags/us.png',
    countryName: 'AmericanSamoa(+684)',
    Action: '',
  },
  {
    id: 2,
    countryCode: 'AD',
    countryId: 984,

    img: 'assets/admin/img/flags/us.png',
    countryName: 'Andorra(+376)',
    Action: '',
  },
  {
    id: 3,
    countryCode: 'AO',
    countryId: 415,
    img: 'assets/admin/img/flags/ao.png',
    countryName: 'Angola(+244)',
    Action: '',
  },
  {
    id: 4,
    countryCode: 'AI',
    countryId: 1264,
    img: 'assets/admin/img/flags/ai.png',
    countryName: 'Anguilla(+1264)',
    Action: '',
  },
  {
    id: 5,
    countryCode: 'AQ',
    countryId: 635,
    img: 'assets/admin/img/flags/qa.png',
    countryName: 'Antarctica(+672)',
    Action: '',
  },
  {
    id: 6,
    countryCode: 'AG',
    countryId: 641,
    img: 'assets/admin/img/flags/ag.png',
    countryName: 'Antigua&Barbuda(+1268)',
    Action: '',
  },
  {
    id: 7,
    countryCode: 'AR',
    countryId: 54,
    img: 'assets/admin/img/flags/ar.png',
    countryName: 'Argentina(+54)',
    Action: '',
  },
  {
    id: 8,
    countryCode: 'AU',
    countryId: 67,
    img: 'assets/admin/img/flags/au.png',
    countryName: 'Australia(+61)',
    Action: '',
  },
];
