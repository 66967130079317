export const adminEarningsData = [
  {
    '#': '1',
    service: 'Computer Repair',
    provider: 'John Smith',
    paymentType: 'Wallet',
    amount: '$38.25',
    commission: '$7.5',
    status: 'Completed',
    date: '07 Oct 2023',
    img: 'assets/admin/img/services/service-03.jpg',
  },
  {
    '#': '2',
    service: 'Car Repair Services',
    provider: 'Johnny',
    paymentType: 'COD',
    amount: '$20',
    commission: '$0',
    status: 'Completed',
    date: '07 Oct 2023',
    img: 'assets/admin/img/services/service-02.jpg',
  },
  {
    '#': '3',
    service: 'Steam Car Wash',
    provider: 'Amanda',
    paymentType: 'Wallet',
    amount: '$10',
    commission: '$6.5',
    status: 'Completed',
    date: '17 Oct 2023',
    img: 'assets/admin/img/services/service-04.jpg',
  },
  {
    '#': '4',
    service: 'House Cleaning',
    provider: 'James',
    paymentType: 'COD',
    amount: '$90',
    commission: '$0.00',
    status: 'Completed',
    date: '17 Oct 2023',
    img: 'assets/admin/img/services/service-09.jpg',
  },
  {
    '#': '5',
    service: 'Computer Repair',
    provider: 'John Smith',
    paymentType: 'Wallet',
    amount: '$38.25',
    commission: '$7.5',
    status: 'Completed',
    date: '07 Oct 2023',
    img: 'assets/admin/img/services/service-03.jpg',
  },
  {
    '#': '6',
    service: 'Car Repair Services',
    provider: 'Johnny',
    paymentType: 'COD',
    amount: '$20',
    commission: '$0',
    status: 'Completed',
    date: '07 Oct 2023',
    img: 'assets/admin/img/services/service-09.jpg',
  },
  {
    '#': '7',
    service: 'Steam Car Wash',
    provider: 'Amanda',
    paymentType: 'Wallet',
    amount: '$10',
    commission: '$6.5',
    status: 'Completed',
    date: '17 Oct 2023',
    img: 'assets/admin/img/services/service-04.jpg',
  },
  {
    '#': '8',
    service: 'House Cleaning',
    provider: 'James',
    paymentType: 'COD',
    amount: '$90',
    commission: '$0.00',
    status: 'Completed',
    date: '17 Oct 2023',
    img: 'assets/admin/img/services/service-09.jpg',
  },
];
